import {
  Stack,
  Heading,
  Text,
  Image,
  Box,
  ListItem,
  ListIcon,
  List,
  useDisclosure,
} from "@chakra-ui/react";
import { NavBar } from "../../components/organisms/Header";
import { MainButton } from "../../components/atoms/MainButtons";
import { Divisor } from "../../components/atoms/Divider";
import { Footer } from "../../components/organisms/Footer";
import { DiscountExamples } from "../../components/organisms/DiscountExamples";
import Fade from "react-reveal";
import { ImageSection } from "../../components/organisms/ImageSection";
import { MdCheckCircle } from "react-icons/md";
import { TestimonyCarousel } from "../../components/organisms/TestimonyCarousel";
import { useEffect, useState } from "react";
import { LoadingScreen } from "../../components/molecules/LoadingScreen";
import { useCheckMobile } from "../../utils/CheckMobile";
import { openPopupWidget } from "react-calendly";
import { ReceiveCall } from "../../components/molecules/ReceiveCall";
import { FixedButtons } from "../../components/molecules/FixedButtons";
import { MobileDrawer } from "../../components/organisms/MobileDrawer";
import depoiments from "../../utils/depoiments";

const BaloonComponent = () => {
  return (
    <Box w="200px" h="300px">
      <Image
        w="auto"
        h="auto"
        src="https://partiu-static.s3.amazonaws.com/images/balao.png"
        alt="Balao animado escrito 3 dias"
      />
      <Box position="relative" top="-100px" left="65px">
        <Heading
          fontFamily="Chivo"
          fontSize="41px"
          fontStyle="bold"
          color="white"
        >
          Dias
        </Heading>
      </Box>
    </Box>
  );
};

const WhatIf = () => {
  return (
    <Stack
      direction={{ base: "column-reverse", lg: "row" }}
      w="100%"
      align="center"
      justify="center"
      pb={{ base: "3vh", lg: "55px" }}
    >
      <Fade right>
        <Box>
          <Heading
            color="black"
            textAlign="center"
            fontFamily="Chivo"
            fontWeight="bold"
          >
            Que tal sua empresa ter um
            <Heading fontFamily="Chivo" color="#E13252" fontWeight="bold">
              clube de vantagens
            </Heading>
            <Heading color="orange" fontWeight="medium" fontFamily="Chivo">
              profissional?
            </Heading>
          </Heading>
        </Box>
        <Image
          src="https://partiu-static.s3.amazonaws.com/images/whatif.png"
          h="auto"
          w="600px"
          alt="A Partiu! traz solucoes para sua empresa"
        />
      </Fade>
    </Stack>
  ); // TODO: Very confusing component name. Need to change it later
};

const ListItens = [
  {
    text: "Lorem ipsum sit dolor amet",
  },
  {
    text: "Lorem ipsum sit dolor amet",
  },
  {
    text: "Lorem ipsum sit dolor amet",
  },
  {
    text: "Lorem ipsum sit dolor amet",
  },
]; // TODO: Add more listItens, with different names

const ComoFicara = () => {
  return (
    <Stack
      direction="column"
      w="100%"
      justify="center"
      align="center"
      spacing={10}
    >
      <Heading
        textAlign="center"
        fontSize="40px"
        fontFamily="Chivo"
        fontWeight="bold"
      >
        Como vai ficar:
      </Heading>
      <Stack direction="row" w="100%" justify="center" align="center">
        <ImageSection
          hasDivisor={true}
          hasList={true}
          isReversed={false}
          src={"/paraclienteim.png"}
          title="Para seu cliente"
          whichList={ListItens}
        />
      </Stack>
    </Stack>
  );
}; // TODO: Don't know if we are going to use only english words in our projects. If it is right, need to change it later

const ForSomething: React.FC<{
  hasList?: boolean,
  hasText: boolean,
  isReversed: boolean,
  src: string,
  title: string,
  text: string,
}> = (props) => {
  const hasList = props.hasList;
  const hasText = props.hasText;

  return (
    <Stack
      direction={{
        base: "column",
        lg: props.isReversed ? "row-reverse" : "row",
      }}
      justify="center"
      align="center"
      w="85%"
      alignSelf="center"
      m="auto"
      p="5%"
    >
      <Image
        w={{ base: "auto", lg: "500px" }}
        h="auto"
        src={props.src}
        alt="Imagem de como ficara o seu clube"
      />
      <Fade left>
        <Stack
          direction="column"
          w="auto"
          h="auto"
          alignContent="flex-start"
          justifyItems="center"
          pt={{ base: "3vh", lg: "initial" }}
        >
          <Divisor />
          <Heading
            color="#DA2257"
            fontFamily="Chivo"
            fontWeight="bold"
            textAlign="left"
          >
            {props.title}
          </Heading>
          {hasList ? (
            <List alignSelf="left">
              {ListItens.map((listItem, index) => {
                return (
                  <>
                    <ListItem
                      fontFamily="Chivo"
                      fontWeight="medium"
                      key={index}
                      fontSize="20px"
                    >
                      <ListIcon as={MdCheckCircle} color="red" />
                      {listItem.text}
                    </ListItem>
                  </>
                );
              })}
            </List>
          ) : (
            <></>
          )}
          {hasText ? (
            <Text fontFamily="Chivo" fontSize="16px">
              {props.text}
            </Text>
          ) : (
            <></>
          )}
        </Stack>
      </Fade>
    </Stack>
  );
};

const DemonstrationMainBox = ({ openReceiveCallModal, bookCalendlyMeet }) => {
  return (
    <>
      <Stack
        direction={{ base: "column-reverse", lg: "row" }}
        w="100%"
        justify="space-evenly"
        pl={{ base: "auto", md: "10%" }}
        align="center"
        bgImage="linear-gradient(45deg, #FA6D35, #BD1A34)"
        position="relative"
        pt="120px"
        pb="130px"
        spacing={10}
      >
        <Stack direction="column" w="auto" spacing={0} align="flex-start">
          <Divisor />
          <Heading color="white" fontFamily="Chivo">
            Pronto para você em
          </Heading>
          <BaloonComponent />
          <Stack direction="row" alignSelf="center" alignItems="center">
            <MainButton
              onClick={openReceiveCallModal}
              text="Receba uma ligacao"
              isWhite={true}
            />
            <Text
              fontFamily="Chivo"
              color="white"
              fontWeight="bold"
              textAlign="center"
            >
              OU
            </Text>
            <MainButton
              onClick={bookCalendlyMeet}
              text="Agende uma reuniao"
              isWhite={true}
            />
          </Stack>
        </Stack>
        <Box position="relative" alignItems="center" justifyContent="center">
          <Fade right>
            <Image
              w="90%"
              h="auto"
              src="https://partiu-static.s3.amazonaws.com/images/ComputadorFlamengo.png"
              zIndex="2"
              position="relative"
              alignSelf="center"
              alt="Clube Partiu! para o socio-torcedor do Flamengo"
            />
          </Fade>
        </Box>
      </Stack>
      <Box
        backgroundColor="white"
        position="relative"
        width="102%"
        height="200px"
        marginBottom="-170px"
        transform={{
          base: "translateY(-20%)",
          lg: "rotate(3deg) translateY(-20%) translateX(-2%);",
        }}
        zIndex="0px"
      >
        <Box
          width={{ base: "50px", lg: "300px" }}
          height={{ base: "100px", lg: "400px" }}
          backgroundColor="transparent"
          boxShadow={{ base: "0px 50px white", lg: "0px 200px white" }}
          borderBottomLeftRadius="200px"
          position="absolute"
          top={{ base: "-100px", lg: "-400px" }}
        />
        <Box
          width={{ base: "50px", lg: "400px" }}
          height={{ base: "100px", lg: "500px" }}
          backgroundColor="transparent"
          boxShadow={{ base: "0px 50px white", lg: "0px 300px white" }}
          borderBottomRightRadius="200px"
          position="absolute"
          right="0px"
          top={{ base: "-100px", lg: "-500px" }}
        />
      </Box>
    </>
  );
};

export default function Demonstracao() {
  const [isLoading, setIsLoading]: [boolean, Function] = useState(true);

  const receiveCallDisclosure = useDisclosure();
  const isMobile = useCheckMobile();

  const url = "https://calendly.com/comercial1-partiu/partiu-conheca-o-superapp";

  const pageSettings = {
    backgroundColor: "cbe5e2",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const onClick = () => openPopupWidget({ url, pageSettings });

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <LoadingScreen isLoading={isLoading}>
      <NavBar isTransparent={true} />
      <DemonstrationMainBox
        openReceiveCallModal={receiveCallDisclosure.onOpen}
        bookCalendlyMeet={onClick}
      />
      <WhatIf />

      <ForSomething
        src={
          "https://partiu-static.s3.amazonaws.com/images/ExemploShopping.png"
        }
        title="Para seu cliente"
        isReversed={true}
        hasText={true}
        text="Não importa se sua empresa é pequena ou grande, nossa solução é completa, modular e adaptável para todas as necessidades. Quem não quer economizar ao comprar produtos e serviços oferecidos pelos principais e-commerces do Brasil e nas principais lojas e restaurantes da sua região? Ao se cadastrar na nossa plataforma, eles terão acesso a descontos relevantes. A utilização dessas vantagens gera uma economia financeira real e considerável."
      />
      <ForSomething
        src={
          "https://partiu-static.s3.amazonaws.com/images/AssemedDashboard.png"
        }
        isReversed={false}
        title="Para sua associação"
        hasText={true}
        text="E se você pudesse potencializar os benefícios já proporcionados pela sua associação ou sindicato e oferecer centenas de novas vantagens exclusivas? Conte com a Partiu! e ofereça, em apenas 3 dias úteis, um clube de vantagens completo, com descontos reais em diversos segmentos."
      />
      <ForSomething
        src={
          "https://partiu-static.s3.amazonaws.com/images/ExemploConvenio.png"
        }
        isReversed={true}
        title="Para seu funcionário"
        hasText={true}
        text="Sabemos que uma equipe motivada trabalha muito mais feliz! Então aumente a produtividade e a motivação de seus colaboradores, disponibilizando um clube de vantagens completo com diversos benefícios."
      />
      <DiscountExamples />
      <TestimonyCarousel
        items={depoiments}
      />
      <Footer />
      <ReceiveCall {...receiveCallDisclosure} />
      <FixedButtons
        openReceiveCallModal={receiveCallDisclosure.onOpen}
        bookCalendlyMeet={onClick}
      />
      {isMobile ? (
        <MobileDrawer openReceiveCallModal={receiveCallDisclosure.onOpen} />
      ) : (
        <></>
      )}
    </LoadingScreen>
  );
}
