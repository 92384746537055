import { Heading, VStack, Text, Box, Flex, Stack } from "@chakra-ui/layout";
import { RoundOverlay } from "../../components/atoms/RoundOverlay";
import { SectionText } from "../../components/atoms/SectionText";
import { SectionTitle } from "../../components/atoms/SectionTitle";
import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import { ImageSection } from "../../components/organisms/ImageSection";
import Fade from "react-reveal";
import { LinkedinBall } from "../../components/atoms/LinkedinBall";
import { Divisor } from "../../components/atoms/Divider";
import { MainButton } from "../../components/atoms/MainButtons";
import { ReceiveCall } from "../../components/molecules/ReceiveCall";
import { FixedButtons } from "../../components/molecules/FixedButtons";
import { MobileDrawer } from "../../components/organisms/MobileDrawer";
import { openPopupWidget } from "react-calendly";
import { useDisclosure } from "@chakra-ui/hooks";
import { useCheckMobile } from "../../utils/CheckMobile";

const About = (props) => {
  return (
    <Fade right>
      <Stack
        direction="column"
        align="center"
        justify="center"
        w="100%"
        spacing={5}
        position="relative"
        zIndex="2"
        p="5% 10% 5% 10%"
      >
        <Divisor />
        <Heading fontFamily="Chivo" color="#DA2257" fontWeight="bold">
          {props.title}
        </Heading>
        <Text
          fontFamily="Chivo"
          fontWeight="medium"
          fontSize="20px"
          textAlign="center"
        >
          {props.description}
        </Text>
        <Stack direction="row" align="center">
          <MainButton isWhite={false} text="Agende uma reuniao" />
          <Text fontFamily="Chivo" fontWeight="extrabold">
            OU
          </Text>
          <MainButton isWhite={false} text="Receba uma ligacao agora" />
        </Stack>
      </Stack>
    </Fade>
  );
};

export default function WhoWeAre() {
  const receiveCallDisclosure = useDisclosure();
  const isMobile = useCheckMobile();

  const url = "https://calendly.com/comercial1-partiu/partiu-conheca-o-superapp";

  const pageSettings = {
    backgroundColor: "cbe5e2",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const onClick = () => openPopupWidget({ url, pageSettings });

  return (
    <Flex direction="column" spacing={0} width="100%">
      <NavBar isTransparent={true} />
      <VStack
        height="530px"
        width="100%"
        align="flex-start"
        backgroundAttachment="fixed"
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundBlendMode="lighten"
        backgroundImage="url('https://partiu-static.s3.us-east-1.amazonaws.com/images/banner-quem-somos.png'), linear-gradient(45deg, rgba(216,43,96, 0.5), rgba(255,168,0, 0.5))"
      >
        <Box paddingTop="150px" paddingLeft="20%" color="white">
          <Heading fontSize="50px" paddingBottom="20px" whiteSpace="nowrap">
            Conheça a Partiu!
          </Heading>
          <Text fontSize="20px" width="300px">
            Atuação nacional com sede no Rio de Janeiro, escritório em São Paulo
            e filias em diversos estados.
          </Text>
        </Box>
      </VStack>
      <RoundOverlay width="103%" />
      <VStack width="90%" align="center" margin="auto">
        <ImageSection
          hideGreyBox={true}
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          isReversed={false}
          hasDivisor={true}
          hasList={false}
          iconSrc="https://partiu-static.s3.amazonaws.com/icons/clienticon.png"
          src={
            "https://partiu-static.s3.amazonaws.com/images/DesenhoComprar.png"
          }
          title="Quem somos"
          text="Fundada em 2015, somos uma startup com atuação nacional, sede no Rio de Janeiro e escritório em 8 estados do país.
          Nosso time composto por 40 colaboradores, com DNA empreendor, comprometido e focado nas novidades do mercado.
          Em 2019 participamos do processo de aceleração na Fábrica de Staturp em parceria com o grupo Aliansce Sonae e fomos escolhidos para fazermos um projeto piloto.
          Há 6 anos somos responsáveis por criar e gerenciar o clube de vantagens de mais de 60 empresas em diversos segmentos. 
          Nosso foco é proporcionar aos nossos clientes um relacionamento duradouro com o público deles, agregar valor a marca e levar benefícios ao consumidor final."
          alt="Imagem genérica"
        />
        <ImageSection
          hideGreyBox={true}
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          isReversed={true}
          hasDivisor={true}
          hasList={false}
          iconSrc="https://partiu-static.s3.amazonaws.com/icons/clienticon.png"
          src={
            "https://partiu-static.s3.amazonaws.com/images/DesenhoComprar.png"
          }
          title="Missão"
          text="Estreitar o relacionamento das empresas com os seus clientes através do clube de vantagens."
          alt="Imagem genérica"
        />
        <ImageSection
          hideGreyBox={true}
          isReversed={false}
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          hasDivisor={true}
          hasList={false}
          src={
            "https://partiu-static.s3.amazonaws.com/images/DesenhoComprar.png"
          }
          title="Visão"
          text="Gerenciar o clube de vantagens de 1.000 empresas até 2025."
        />
        <ImageSection
          hideGreyBox={true}
          isReversed={true}
          openReceiveCallModal={receiveCallDisclosure.onOpen}
          bookCalendlyMeet={onClick}
          hasDivisor={true}
          hasList={false}
          src={
            "https://partiu-static.s3.amazonaws.com/images/DesenhoComprar.png"
          }
          title="Valores"
          text="Meritrocracia, Cliente como foco, Transparencia, e vamos tentar sempre sem medo de errar"
        />
        <SectionTitle pt="50px">Heads da empresa</SectionTitle>
        <VStack pb="50px" width="70%" maxW="750px" spacing={6}>
          <Stack
            width="100%"
            justify="space-between"
            direction={{ base: "column", lg: "row" }}
            pt="50px"
          >
            <Fade down>
              <LinkedinBall
                name="Vagner Andrade"
                job="CEO"
                linkedinUrl="https://www.linkedin.com/in/vagner-andrade-msc-609ab312/"
                avatarSrc="https://partiu-static.s3.amazonaws.com/faces/vagner.jpeg"
                email="vagner@partiu.com.br"
              />
              <LinkedinBall
                name="Paula Motta"
                job="Diretora de Projetos"
                linkedinUrl="https://www.linkedin.com/in/paulatvmotta/"
                avatarSrc="https://media-exp1.licdn.com/dms/image/C4E03AQFXag0kTqs-pQ/profile-displayphoto-shrink_400_400/0/1559928278622?e=1637798400&v=beta&t=5GwDoOOXRei3pUxKqfskC7icqbFDT9rS3P3jPgzBMKo"
                email="paula@partiu.com.br"
              />
              <LinkedinBall
                name="Felipe Ferreira"
                job="Diretor Comercial"
                linkedinUrl="https://www.linkedin.com/in/felipe-ferreira-247396167/"
                avatarSrc="https://media-exp1.licdn.com/dms/image/C4D03AQEPmcKdzbJXNA/profile-displayphoto-shrink_400_400/0/1530711658966?e=1637798400&v=beta&t=4PUlrz9K9WWxQqO2k1OZJ5dmS60Y0qjZSNuwPNW6VZs"
                email="felipe@partiu.com.br"
              />
              <LinkedinBall
                name="Breno Gomes"
                job="CTO"
                linkedinUrl="https://www.linkedin.com/in/breno-gomes-0a1457122/"
                avatarSrc="https://media-exp1.licdn.com/dms/image/C5603AQGh6o11n1S56Q/profile-displayphoto-shrink_400_400/0/1606172348015?e=1637798400&v=beta&t=KjTidiER5DEEny1K0wZcPM2VPOrSgs_QxcqRBg67vPE"
                email="breno@partiu.com.br"
              />
            </Fade>
          </Stack>
        </VStack>
      </VStack>
      <Footer />
      <ReceiveCall {...receiveCallDisclosure} />
      <FixedButtons
        openReceiveCallModal={receiveCallDisclosure.onOpen}
        bookCalendlyMeet={onClick}
      />
      {isMobile ? (
        <MobileDrawer openReceiveCallModal={receiveCallDisclosure.onOpen} />
      ) : (
        <></>
      )}
    </Flex>
  );
}
