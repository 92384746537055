import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import {
  BePartnerMainBox,
  MainBePartner,
} from "../../components/organisms/BePartnerMainBox";
import { VerticaisCarousel } from "../../components/organisms/VerticaisCarousel";
import { WorldOfDiscounts } from "../../components/organisms/WorldOfDiscounts";
import { ImageSection } from "../../components/organisms/ImageSection";
import { Morethan } from "../../components/organisms/Morethan";
import { DashboardSection } from "../../components/organisms/Dashboard";
import { TestimonyCarousel } from "../../components/organisms/TestimonyCarousel";
import { Button } from "@chakra-ui/button";
import { Link } from "@chakra-ui/layout";
import depoiments from "../../utils/depoiments";

const beList = [
  {
    index: 1,
    text: "Lorem ipsum sit dolor amet",
  },
  {
    index: 2,
    text: "Loram ipsum sit dolor amet 2",
  },
  {
    index: 3,
    text: "Lorem ipsum sit amet dolor 4",
  },
];

const nationalEcommerceList = [
  {
    index: 1,
    text: "Lembre-se que é importante conceder um desconto real e o mais atrativo possível.",
  },
  {
    index: 2,
    text: "Preencha o formulário online de cadastro. Em breve o nosso consultor entrará em contato com você para que a parceria seja confirmada!",
  },
];

export default function BePartner() {
  return (
    <>
      <NavBar />
      <MainBePartner />
      <WorldOfDiscounts />
      <Morethan />
      <ImageSection
        iconSrc={
          "https://partiu-static.s3.amazonaws.com/icons/shopping-cart.png"
        }
        hideButtons={true}
        src={"https://partiu-static.s3.amazonaws.com/images/ecom.png"}
        title="E-commerce nacional"
        alt="Exemplos de descontos que a Partiu oferece."
        isReversed={true}
        hasList={true}
        whichList={nationalEcommerceList}
        text="Você tem uma loja virtual que atende todo o país? Ótimo, então faça parte do Clube!"
      >
        <Link marginTop="30px" href="#top">
          <Button>Cadastre-se Já!</Button>
        </Link>
      </ImageSection>

      <ImageSection
        hideButtons={true}
        iconSrc={"https://partiu-static.s3.amazonaws.com/icons/ecommerce.png"}
        src={"https://partiu-static.s3.amazonaws.com/images/elocal.png"}
        title="E-commerce local"
        alt="Exemplos de descontos que a Partiu oferece."
        isReversed={false}
        hasList={true}
        whichList={nationalEcommerceList}
        text="Que tal aumentar as vendas da sua loja virtual? Se você tem um ecommerce que faz entrega na região da sua cidade, cadastre-se agora!"
      >
        <Link marginTop="30px" href="#top">
          <Button>Cadastre-se Já!</Button>
        </Link>
      </ImageSection>

      <ImageSection
        hideButtons={true}
        iconSrc={"https://partiu-static.s3.amazonaws.com/icons/shop.png"}
        src={"https://partiu-static.s3.amazonaws.com/images/nacional.png"}
        title="Loja Fisica"
        alt="Exemplos de descontos que a Partiu oferece."
        isReversed={true}
        hasList={true}
        whichList={nationalEcommerceList}
        text="Gostaria de aumentar o fluxo de clientes do seu estabelecimento físico? Seja nosso parceiro!"
      >
        <Link marginTop="30px" href="#top">
          <Button>Cadastre-se Já!</Button>
        </Link>
      </ImageSection>
      <ImageSection
        hideButtons={true}
        isReversed={false}
        hasDivisor={true}
        src={"https://partiu-static.s3.amazonaws.com/images/Sistema_pdv.png"}
        text="Acesse o seu canal exclusivo através de login e senha. Você terá acesso a um painel simples e intuitivo para validação dos descontos, transações, ativações e acompanhamento."
        title="Uma plataforma completa para você administrar e visualizar as vendas realizadas."
      >
        <Link marginTop="30px" href="#top">
          <Button>Cadastre-se Já!</Button>
        </Link>
      </ImageSection>
      <VerticaisCarousel />
      <TestimonyCarousel
        items={depoiments}
      />
      <Footer />
    </>
  );
}
